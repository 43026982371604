// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../../assets/images/dashboard/bg.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "@charset \"utf-8\";\nbody{font-family:Tahoma,Arial,Helvetica,\"微软雅黑\";font-size: 14px;color:#fff;height: 100%;}\nbody {\n  background-attachment: fixed;\n  background:#fff url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat center;\n}\n\n\n\n\n\n\n\n\n\n", ""]);
// Exports
module.exports = exports;
